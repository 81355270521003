import React, { Dispatch } from "react";
import { FC, createContext, useContext, useReducer } from "react";
import { isBrowser } from "old/src/utils";
import { Reducer } from "./reducer";
import type { Grade, Subject } from "src/api/parent";
import { localStorage } from "@web-monorepo/safe-browser-storage";

const bookingString = isBrowser() && localStorage.getItem("booking");
const checkoutFunnel = isBrowser() && localStorage.getItem("funnel");
const doNotRunExperiments = isBrowser() && localStorage.getItem("doNotRunExperiments") === "true";

type ScheduleDay = {
  time: string;
  isAvailable: boolean;
};

export type BookingType = {
  assessment?: {
    frequency: number;
  } | null;
  classBookingId: number;
  date: string;
  email: string;
  first_name_child: string;
  first_name_parent: string;
  grade: Grade;
  id: number;
  last_name_parent: string;
  mobile_number: string;
  updatedAt: string;
  price: {
    id: number;
    active: boolean;
    description: string;
    name: string;
    unit_amount: number;
    recurring_interval: string;
    quantity: number;
  };
  subject: Subject;
  subscriptionClassId: number;
  teacher?: {
    id: number;
    first_name: string;
    last_name: string;
    photo_url: string;
    uid: string;
  };
  teacher_uid?: string;
  time: string;
  timezone: string;
  type: string;
  uid: string;
  subscription?: {
    monday?: ScheduleDay;
    tuesday?: ScheduleDay;
    wednesday?: ScheduleDay;
    thursday?: ScheduleDay;
    friday?: ScheduleDay;
    saturday?: ScheduleDay;
    sunday?: ScheduleDay;
  };
  tutorSelected?: boolean;
  product: string;
  accommodations?: string[];
  interests?: string[];
  goals?: string[];
  specializations?: string[];
  skipSpecializationStep?: boolean;
  learningNeeds?: string[];
  quantity?: number;
  additionalChildNames?: string[];
  isNewChild?: boolean;
  isNewPaymentCard?: boolean;
  replacementForSession?: string;
  promoCode?: string;
  typeOfTrial?: "directSubscription" | "single";
};

export type StoreStateType = {
  booking?: BookingType;
  bookModal: boolean;
  checkoutFunnel?: Record<string, any>;
  doNotRunExperiments: boolean;
};

export type StoreActionType = {
  type: string;
  payload?: any;
};

export type StoreContextType = [StoreStateType, Dispatch<StoreActionType>];

const loadBookingFromStorage = (): BookingType | undefined => {
  if (bookingString == null) return undefined;

  // TODO: why is false part of the booking type?
  if (bookingString === false) return undefined;

  const booking = JSON.parse(bookingString) as BookingType;

  const oneWeekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
  if (booking == null || booking.updatedAt == null || new Date(booking.updatedAt) < oneWeekAgo) return undefined;

  return booking;
};

const initialState: StoreStateType = {
  booking: loadBookingFromStorage(),
  bookModal: false,
  checkoutFunnel: checkoutFunnel ? (JSON.parse(checkoutFunnel) as any) : undefined,
  doNotRunExperiments: doNotRunExperiments || false,
};

const Store = createContext<StoreContextType>([initialState, () => null]);

export const StoreProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};

export const useGlobalStore = (): StoreContextType => {
  const [state, dispatch] = useContext<StoreContextType>(Store);
  return [state, dispatch];
};
