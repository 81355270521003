import * as logClient from "@classdojo/log-client";
import useWatch from "@classdojo/web/hooks/useWatch";
import { useCallback, useMemo, useState } from "react";
import { ZendeskAPI } from "react-zendesk";
import { getEntityType } from "./api/auth/useLoginMutation";
import useDecagonMetadata from "./api/parent/useDecagonMetadata";
import { getSessionData } from "./useSession";

type ZendeskWidgetFields = {
  email?: string;
  name?: string;
};

const ticketIdByUserType = {
  parent: 24658308388109,
  teacher: 24314991618061,
};

export type WindowWithHelperAPIMethods = Window &
  typeof globalThis & {
    duet?: {
      setUserId: (userId: string) => void;
      setMetadata: (metadata: Record<string, unknown>) => void;
      open: () => void;
      unhide: () => void;
      hide: () => void;
      setEventListener: (callback: (event: { eventName: string }) => void) => void;
    };
  };

const windowWithDecagon = window as WindowWithHelperAPIMethods;
export const callDecagonMethod = (callback: () => void) => {
  // silent the error since we don't want to break the app if the method is not available
  // this error handling is only for the decagon widget
  try {
    callback(); // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (e) {
    console.error(e); // eslint-disable-line no-console
  }
};

const stringifyValues = (obj: Record<string, unknown>) => {
  const result: { [key: string]: string } = {};
  Object.entries(obj).forEach(([key, value]) => {
    result[key] = JSON.stringify(value);
  });
  return result;
};

const useHelperWidgetAPI = () => {
  const [chatInitiated, setChatInitiated] = useState(false);
  const sessionData = getSessionData();
  const isDecagonWidget = !!sessionData && sessionData.roles.includes("parent");

  const { data: decagonMetadata } = useDecagonMetadata(chatInitiated);

  useWatch([decagonMetadata, sessionData], () => {
    if (!sessionData || decagonMetadata == null) return;
    const userType = getEntityType(sessionData.roles);
    callDecagonMethod(() => {
      windowWithDecagon.duet?.setMetadata({
        email: sessionData.user_details.email,
        user_type: userType === "teacher" ? "tutor" : userType,
        ...stringifyValues(decagonMetadata),
      });
    });
  });

  const setWidgetUserData = ({
    userType,
    email,
    fullname,
    caller,
  }: {
    userType: "parent" | "teacher";
    email: string;
    fullname: string;
    caller: string;
  }) => {
    if (!email) {
      logClient.logMessage("WARN", `Setting widget user data without email: ${email}.  Set by ${caller}`);
    }

    callDecagonMethod(() => windowWithDecagon.duet?.setUserId(email));
    callDecagonMethod(() =>
      windowWithDecagon.duet?.setMetadata({
        email,
        user_type: userType === "teacher" ? "tutor" : userType,
      }),
    );

    ZendeskAPI("webWidget", "updateSettings", {
      webWidget: {
        contactForm: {
          ticketForms: [{ id: ticketIdByUserType[userType] }],
          fields: [
            { id: "email", prefill: { "*": email || "" } },
            { id: "name", prefill: { "*": fullname || "" } },
          ],
        },
      },
    });
  };

  const setDecagonEventListener = useCallback(() => {
    if (!isDecagonWidget) return;

    callDecagonMethod(() =>
      windowWithDecagon.duet?.setEventListener((event) => {
        if (event.eventName === "DUET_INITIALIZE") {
          setChatInitiated(true);

          if (sessionData.user_details.email) {
            windowWithDecagon.duet?.setUserId(sessionData.user_details.email);
            const userType = getEntityType(sessionData.roles);
            windowWithDecagon.duet?.setMetadata({
              email: sessionData.user_details.email,
              user_type: userType === "teacher" ? "tutor" : userType,
            });
          } else {
            windowWithDecagon.duet?.setMetadata({
              email: sessionData.user_details.email,
              user_type: "parent",
              loggedIn: false,
            });
          }
        }
      }),
    );
  }, [isDecagonWidget, sessionData?.roles, sessionData?.user_details.email]);

  const openWidget = useCallback(
    ({ email: _email, name: _name }: ZendeskWidgetFields) => {
      if (isDecagonWidget) {
        callDecagonMethod(() => windowWithDecagon.duet?.open());
      } else {
        ZendeskAPI("webWidget", "updateSettings", {
          webWidget: {
            contactForm: {
              fields: [
                { id: "email", prefill: { "*": _email || "" } },
                { id: "name", prefill: { "*": _name || "" } },
              ],
            },
          },
        });
        ZendeskAPI("webWidget", "open");
      }
    },
    [isDecagonWidget],
  );

  const showWidget = useCallback(() => {
    if (isDecagonWidget) {
      ZendeskAPI("webWidget", "hide");
      callDecagonMethod(() => windowWithDecagon.duet?.unhide());
    } else {
      callDecagonMethod(() => windowWithDecagon.duet?.hide());
      ZendeskAPI("webWidget", "show");
    }
  }, [isDecagonWidget]);

  const hideWidget = () => {
    callDecagonMethod(() => windowWithDecagon.duet?.hide());
    ZendeskAPI("webWidget", "hide");
  };

  return useMemo(
    () => ({ setWidgetUserData, openWidget, showWidget, hideWidget, setDecagonEventListener }),
    [openWidget, setDecagonEventListener, showWidget],
  );
};

export default useHelperWidgetAPI;
