import { BodyText, Button } from "@classdojo/web/nessie";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import startCase from "lodash/startCase";
import { Fragment } from "react";
import ModalWrapper from "src/components/Modals/ModalWrapper";
import { DAY_KEYS } from "src/settings/subscription/constants";

type DayConflictsModalProps = {
  conflicts: Array<{ name: string; conflicts: string[] }>;
  day: DAY_KEYS;
  onClose: () => void;
};

const DayConflictsModal = ({ conflicts, day, onClose }: DayConflictsModalProps) => {
  return (
    <ModalWrapper variant="centered" title={startCase(day)} onClose={onClose}>
      <BodyText>Based on your changes, all affected families are:</BodyText>
      <div sx={sxRowsWrapper}>
        {conflicts.map(({ name, conflicts }, index) => (
          <Fragment key={index}>
            <div sx={sxDivider} />
            <BodyText sx={sxName}>{name}</BodyText>
            <BodyText sx={sxTime} kind="danger">
              {startCase(day)} {conflicts[0]}
            </BodyText>
          </Fragment>
        ))}
        <div sx={sxDivider} />
      </div>
      <BodyText>
        The affected families will be asked to pick a new time on your schedule. If they can’t find time, they’ll either
        be assigned to a new tutor, or cancel their subscription.
      </BodyText>
      <Button
        type="button"
        data-name="teacher_availability.conflicts_modal.okay"
        kind="primary"
        size="l"
        sx={sxButton}
        onClick={onClose}
      >
        Okay
      </Button>
    </ModalWrapper>
  );
};

export default DayConflictsModal;

const sxRowsWrapper: ThemeUIStyleObject = {
  marginTop: "dt_m",
  marginBottom: "dt_m",
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "dt_m",
};

const sxDivider: ThemeUIStyleObject = {
  borderTop: "dt_divider",
  gridColumn: "1 / span 2",
};

const sxName: ThemeUIStyleObject = {
  gridColumn: 1,
};

const sxTime: ThemeUIStyleObject = {
  color: "dt_content_danger",
  gridColumn: 2,
  textDecoration: "line-through",
};

const sxButton: ThemeUIStyleObject = {
  width: "100%",
  marginTop: "dt_l",
};
