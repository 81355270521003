type FeatureSwitchName = `web_marketplace_${string}` | `api_marketplace_${string}`;

type LoggedOutSwitchMap = Record<
  FeatureSwitchName,
  {
    entityType: "parent" | "teacher";
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

type LoggedInSwitchMap = Record<
  FeatureSwitchName,
  {
    values: readonly ["off", "control", ...string[]] | readonly ["off", "on"];
  }
>;

export const LOGGED_OUT_SWITCH_MAP = {
  web_marketplace_trial_parent_agency_holdout: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_date_cleanup_v2_nov2024: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_algolia_search_parent_value_v3: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_algolia_search_tutor_value_v3: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_date_time_picker_jan2025: {
    entityType: "parent",
    values: ["off", "control", "variant1", "variant2"],
  },
  web_marketplace_tutor_selection_info_jan2025: {
    entityType: "parent",
    values: ["off", "control", "test"],
  },
  web_marketplace_account_creation_value_v2_feb2025: {
    entityType: "parent",
    values: ["off", "control", "variant1", "variant2", "variant3"],
  },
} as const satisfies LoggedOutSwitchMap;

export const LOGGED_IN_SWITCH_MAP = {
  web_marketplace_subscription_funnel_prices_jan2025: {
    values: ["off", "control", "variant1", "variant2"],
  },
  web_marketplace_post_trial_book_another_trial_dec2024: {
    values: ["off", "control", "test"],
  },
  web_marketplace_book_more_classes_dec2024: {
    values: ["off", "control", "test"],
  },
  web_marketplace_direct_to_sub_with_variants_feb2025_v2: {
    values: ["off", "control", "variant1", "variant2"],
  },
  web_marketplace_generated_lesson_plan_activities: {
    values: ["off", "control", "test"],
  },
} as const satisfies LoggedInSwitchMap;
