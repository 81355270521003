import { showNotification } from "@mantine/notifications";
import { DefaultOptions, QueryClient, QueryClientConfig } from "@tanstack/react-query";
import { clearSessionQueryData } from "./hooks/useSession";
import { checkIsCoppaError } from "old/src/hooks/useParent";

interface ErrorDetail {
  type: number;
  message: string;
  detail?:
    | string
    | {
        error: string;
      };
  code?: string;
  expected?: true;
}

export interface Error {
  code: string;
  message: string;
  name: string;
  response: {
    data: { error: ErrorDetail };
  };
}

const constructMessage = (error: Error) => {
  const errorData = error?.response?.data?.error;
  let message = "An error has occurred. Please try again in a few minutes or you may send us a message.";

  if (errorData?.detail) {
    if (typeof errorData.detail === "string") {
      message = errorData.detail;
    } else if (typeof errorData.detail === "object" && errorData.detail.error) {
      message = errorData.detail.error;
    }
  }

  return message;
};

export const handleError = (ignoreExpected = false, notificationDetails?: { title?: string; message?: string }) => {
  return (error: Error) => {
    // Ignore errors without a server response, these are handled in Axios config
    if (error.code === "ERR_NETWORK") {
      return;
    }

    const errorData = error.response.data.error;

    if (ignoreExpected && errorData.expected) {
      return;
    }

    // if we get a 401 we should redirect to the login page
    if (errorData.type === 401) {
      clearSessionQueryData();
      window.location.href = "#/login";
      return;
    }

    // we shouldn't show notification on COPPA error since we should show the COPPA consent modal instead
    if (checkIsCoppaError(error)) {
      return;
    }

    showNotification({
      title: notificationDetails?.title ?? errorData.message,
      message: notificationDetails?.message ?? constructMessage(error),
      color: "red",
    });
  };
};

const defaultOptions: DefaultOptions<Error> = {
  queries: {
    onError: handleError(),
    retry: false,
  },
};

const config: QueryClientConfig = {
  // @ts-expect-error Type '(error: Error) => void' is not assignable to type '(err: unknown) => void'.
  defaultOptions,
};

export default new QueryClient(config);
