import { useConvertExperimentOnLoad } from "#/src/hooks/useFeatureSwitches";
import { LoadingMojo } from "@classdojo/web";
import { useOnFirstRender } from "@web-monorepo/hooks";
import { useGlobalStore } from "old/src/store";
import { actionTypes } from "old/src/store/actionTypes";
import { useNavigate } from "react-router";
import { logTutorEvent } from "src/utils/log";

const AdditionalSingleSessionSuccess = () => {
  const push = useNavigate();
  const [state, dispatch] = useGlobalStore();
  useConvertExperimentOnLoad("web_marketplace_book_more_classes_dec2024");

  useOnFirstRender(() => {
    if (state.booking) {
      logTutorEvent({
        eventName: "parent.marketplace.logged_in_trial_booking.success",
        metadata: {
          card: state.booking.isNewPaymentCard ? "new" : "old",
        },
        experiments: ["web_marketplace_post_trial_book_another_trial_dec2024"],
      });
      dispatch({
        type: actionTypes.UPDATE_BOOKING,
        payload: null,
      });
    }
    setTimeout(() => {
      push("/parents/classes");
    }, 5000);
  });

  return (
    <div
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "40px",
      }}
    >
      <LoadingMojo border />
    </div>
  );
};

export default AdditionalSingleSessionSuccess;
